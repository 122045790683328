import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

const defaultState = {
  languages: [],
  mobileNav: {},
  currentLangCode: '',
  switchLanguage: () => {},
};

const MenuContext = React.createContext(defaultState);

function MenuContextProvider(props) {
  const data = useStaticQuery(graphql`
    query {
      navEN: wpMenu(locations: {eq: MOBILE_NAV___EN}) {
        id
        menuItems {
          nodes {
            id
            label
            url
            path
          }
        }
      }
      navES: wpMenu(locations: {eq: MOBILE_NAV}) {
        id
        menuItems {
          nodes {
            id
            label
            url
            path
          }
        }
      }
      langs: wp {
        languages {
          code
          slug
          name
          locale
          id
          homeUrl
        }
        defaultLanguage {
          slug
          name
          locale
          id
          code
          homeUrl
        }
      }
    }
  `);

  const { navEN, navES, langs } = data;
  const { children } = props;

  const [languages] = useState(langs.languages);
  const [currentLangCode, setCurrentLangCode] = useState(langs.defaultLanguage.code.toLowerCase());

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = {
    languages,
    currentLangCode,
    mobileNav: currentLangCode === 'en' ? navEN : navES,
    switchLanguage: setCurrentLangCode,
  };

  return (
    <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>
  );
}
MenuContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default MenuContext;
export { MenuContextProvider };
