const keys = {
  THANKYOU_MESSAGE: "THANKYOU_MESSAGE",
  COOKIE_CONSENT_MESSAGE: "COOKIE_CONSENT_MESSAGE",
  COOKIE_CONSENT_ACCEPT_TEXT: "COOKIE_CONSENT_ACCEPT_TEXT",
  CONTACT_FORM_TITLE: "CONTACT_FORM_TITLE",
  CONTACT_FORM_NOTE: "CONTACT_FORM_NOTE",
  FORM_FIELD_NAME_LABEL: "FORM_FIELD_NAME_LABEL",
  FORM_FIELD_EMAIL_LABEL: "FORM_FIELD_EMAIL_LABEL",
  FORM_FIELD_RESTAURANT_LABEL: "FORM_FIELD_RESTAURANT_LABEL",
  FORM_FIELD_RESTAURANT_PLACEHOLDER: "FORM_FIELD_RESTAURANT_PLACEHOLDER",
  FORM_FIELD_TOPIC_LABEL: "FORM_FIELD_TOPIC_LABEL",
  FORM_FIELD_TOPIC_PLACEHOLDER: "FORM_FIELD_TOPIC_PLACEHOLDER",
  FORM_FIELD_TOPIC_OPTION_1: "FORM_FIELD_TOPIC_OPTION_1",
  FORM_FIELD_TOPIC_OPTION_2: "FORM_FIELD_TOPIC_OPTION_2",
  FORM_FIELD_TOPIC_OPTION_3: "FORM_FIELD_TOPIC_OPTION_3",
  FORM_FIELD_TOPIC_OPTION_4: "FORM_FIELD_TOPIC_OPTION_4",
  FORM_FIELD_INFORMATION_LABEL: "FORM_FIELD_INFORMATION_LABEL",
  FORM_SUBMIT_TEXT: "FORM_SUBMIT_TEXT",
  FORM_ERROR_MESSAGES_TITLE: "FORM_ERROR_MESSAGES_TITLE",
  FORM_ERROR_MESSAGE: "FORM_ERROR_MESSAGE",
  CLOSE_POPUP_LABEL: "CLOSE_POPUP_LABEL",
  RESERVE_BUTTON_LABEL: "RESERVE_BUTTON_LABEL",
};

const translations = {
  en: {
    THANKYOU_MESSAGE:
      "Thank you for contacting us. <br />We will get in touch with you soon.",
    COOKIE_CONSENT_MESSAGE: `We use cookies to improve your overall online experience. By clicking on 'Accept' you consent to the use of cookies.`,
    COOKIE_CONSENT_ACCEPT_TEXT: "Accept",
    CONTACT_FORM_TITLE: "CONTACT<br /><span>FORM</span>",
    CONTACT_FORM_NOTE: "RESERVATIONS ONLY BY PHONE",
    FORM_FIELD_NAME_LABEL: "NAME:",
    FORM_FIELD_EMAIL_LABEL: "EMAIL:",
    FORM_FIELD_RESTAURANT_LABEL: "RESTAURANT:",
    FORM_FIELD_RESTAURANT_PLACEHOLDER: "Select a restaurant",
    FORM_FIELD_TOPIC_LABEL: "TOPIC:",
    FORM_FIELD_TOPIC_PLACEHOLDER: "Select a value",
    FORM_FIELD_TOPIC_OPTION_1: "INVOICE REQUEST",
    FORM_FIELD_TOPIC_OPTION_2: "ALLERGENS",
    FORM_FIELD_TOPIC_OPTION_3: "INFORMATION",
    FORM_FIELD_TOPIC_OPTION_4: "OTHER TOPIC",
    FORM_FIELD_INFORMATION_LABEL: "ADDITIONAL INFORMATION:",
    FORM_SUBMIT_TEXT: "SEND",
    FORM_ERROR_MESSAGES_TITLE: "Please fix the following errors:",
    FORM_ERROR_MESSAGE: "An error occured.<br />Please try again later.",
    CLOSE_POPUP_LABEL: "Close",
    RESERVE_BUTTON_LABEL: "Reserve",
  },
  es: {
    THANKYOU_MESSAGE:
      "Gracias por contactarnos. <br />En breve nos pondremos en contacto contigo.",
    COOKIE_CONSENT_MESSAGE: `Utilizamos cookies para proporcionarte la mejor experiencia posible en nuestra página web. Al hacer clic en “Aceptar cookies”, estás de acuerdo con esto.`,
    COOKIE_CONSENT_ACCEPT_TEXT: "Aceptar cookies",
    CONTACT_FORM_TITLE: "FORMULARIO DE<br /><span>CONTACTO</span>",
    CONTACT_FORM_NOTE: "Reservas solo por teléfono",
    FORM_FIELD_NAME_LABEL: "NOMBRE:",
    FORM_FIELD_EMAIL_LABEL: "EMAIL:",
    FORM_FIELD_RESTAURANT_LABEL: "RESTAURANTE:",
    FORM_FIELD_RESTAURANT_PLACEHOLDER: "Seleccionar el restaurante",
    FORM_FIELD_TOPIC_LABEL: "ASUNTO:",
    FORM_FIELD_TOPIC_PLACEHOLDER: "Asunto",
    FORM_FIELD_TOPIC_OPTION_1: "SOLICITUD DE FACTURA",
    FORM_FIELD_TOPIC_OPTION_2: "ALÉRGENOS",
    FORM_FIELD_TOPIC_OPTION_3: "INFORMACIÓN",
    FORM_FIELD_TOPIC_OPTION_4: "OTRAS CONSULTAS",
    FORM_FIELD_INFORMATION_LABEL: "INFORMACIÓN ADICIONAL:",
    FORM_SUBMIT_TEXT: "ENVIAR",
    FORM_ERROR_MESSAGES_TITLE: "Por favor, corrija los siguientes errores:",
    FORM_ERROR_MESSAGE:
      "Ha ocurrido un error.<br />Por favor inténtelo de nuevo más tarde.",
    CLOSE_POPUP_LABEL: "Close",
    RESERVE_BUTTON_LABEL: "Reservas",
  },
};

export { translations, keys };
