/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";
import TemplateContext from "../context/template-context";
import MenuContext from "../context/menu-context";

import CookieConsent from "react-cookie-consent";
import { useLocation } from "@reach/router"; // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import Translator from "../utilities/Translator";
import { keys } from "../utilities/translations";

function Layout(props) {
  const {
    wp: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wp {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `);

  const { className, children, pageContext, t } = props;
  const { languageCode: pageLangCode, template } = pageContext;
  const ctx = useContext(TemplateContext);
  const menuContext = useContext(MenuContext);
  const { currentLangCode, switchLanguage } = menuContext;
  // Enable tracking on cookie accept
  const location = useLocation();
  const startTracking = () => {
    initializeAndTrack(location);
  };

  // Set the current language initially in the context
  useEffect(() => {
    if (
      pageLangCode &&
      currentLangCode &&
      pageLangCode.toLowerCase() !== currentLangCode
    ) {
      switchLanguage(pageLangCode.toLowerCase());
    }
  }, [pageLangCode, currentLangCode]);

  useEffect(() => {
    ctx.setTemplate(template);
  }, [template]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SEOContext.Provider value={{ global: seo }}>
      <div className={["site-wrapper", className].join(" ")}>{children}</div>

      <CookieConsent
        location="bottom"
        buttonText={t(keys.COOKIE_CONSENT_ACCEPT_TEXT, currentLangCode)}
        disableStyles={true}
        containerClasses="cookie-consent-wrap"
        contentClasses="cookie-consent-content-wrap"
        buttonWrapperClasses="cookie-consent-buttons-wrap"
        cookieName="gatsby-gdpr-google-tagmanager"
        onAccept={startTracking}
      >
        {t(keys.COOKIE_CONSENT_MESSAGE, currentLangCode)}
      </CookieConsent>
    </SEOContext.Provider>
  );
}

Layout.defaultProps = {
  className: "",
  children: "",
};
Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  pageContext: PropTypes.shape({
    template: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};
export default Translator(Layout);
