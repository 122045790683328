/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const defaultState = {
  template: '',
  setTemplate: () => {},
};

const TemplateContext = React.createContext(defaultState);

function TemplateContextProvider(props) {
  const { children } = props;
  const [template, setTemplate] = useState(null);

  const contextValue = {
    template,
    setTemplate,
  };

  return <TemplateContext.Provider value={contextValue}>{children}</TemplateContext.Provider>;
}
TemplateContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default TemplateContext;
export { TemplateContextProvider };
