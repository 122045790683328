import React from 'react';

import { translations } from './translations';

// eslint-disable-next-line func-names
const Translator = (WrappedComponent) => function (moreProps) {
  const translator = (key, currentLangCode) => {
    if (currentLangCode) {
      return translations[currentLangCode][key];
    }
    return translations.es[key];
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WrappedComponent {...moreProps} t={translator} />;
};

export default Translator;
