exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-us-jsx": () => import("./../../../src/templates/about-us.jsx" /* webpackChunkName: "component---src-templates-about-us-jsx" */),
  "component---src-templates-allergy-jsx": () => import("./../../../src/templates/allergy.jsx" /* webpackChunkName: "component---src-templates-allergy-jsx" */),
  "component---src-templates-blog-archive-jsx": () => import("./../../../src/templates/blog-archive.jsx" /* webpackChunkName: "component---src-templates-blog-archive-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-carta-jsx": () => import("./../../../src/templates/carta.jsx" /* webpackChunkName: "component---src-templates-carta-jsx" */),
  "component---src-templates-category-allergy-jsx": () => import("./../../../src/templates/category-allergy.jsx" /* webpackChunkName: "component---src-templates-category-allergy-jsx" */),
  "component---src-templates-contact-us-jsx": () => import("./../../../src/templates/contact-us.jsx" /* webpackChunkName: "component---src-templates-contact-us-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-menu-del-medio-dia-jsx": () => import("./../../../src/templates/menu-del-medio-dia.jsx" /* webpackChunkName: "component---src-templates-menu-del-medio-dia-jsx" */),
  "component---src-templates-restaurant-jsx": () => import("./../../../src/templates/restaurant.jsx" /* webpackChunkName: "component---src-templates-restaurant-jsx" */)
}

