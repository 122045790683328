/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import './src/assets/styles/main.scss';

import React from 'react';
import Layout from './src/components/Layout';
import { MenuContextProvider } from './src/context/menu-context';
import { TemplateContextProvider } from './src/context/template-context';

export const wrapRootElement = ({ element }) => (
  <MenuContextProvider>
    <TemplateContextProvider>
      {element}
    </TemplateContextProvider>
  </MenuContextProvider>
);

export const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>;
